import React from "react";
import MoreToRead from "./ProfileMoreNews";
import Perfil4  from '../imagenes/Perfil/Perfil4.png';
import { Link } from "react-router-dom";
export default function ProfileNews() {
  return (
    <div className="bg-white p-6 font-sans">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col lg:flex-row gap-10 ">
          {/* Left Column */}
          <MoreToRead />

          <div className="hidden lg:block lg:w-px bg-gray-700 mx-4"></div>
          {/* Right Column */}
          <div className="lg:w-1/3 mr-3">
            
          </div>
        </div>
      </div>
    </div>
  );
}
