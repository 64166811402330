import React from "react";
import news5 from "../imagenes/News/news5.png";
import { Link } from "react-router-dom";
const Democracia = () => {
  const NewsItem = ({ title, author, date, imageUrl }) => (
    <div className="mb-8 pb-8 border-b border-black font-sans">
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2 md:pr-4">
          <Link to="post" className="no-underline text-black">
            <h4 className="text-3xl prompt-semibold mb-2 mt-6 ">{title}</h4>
          </Link>
          <Link to="perfil" className="no-underline">
            <p className="text-base prompt-regular text-black mt-8">
              By {author}
            </p>
          </Link>

          <p className="text-base prompt-regular text-black mt-2 ">{date}</p>
        </div>
        <div className="md:w-1/2 mt-4 md:mt-0">
        <Link to='post'>
        <img src={imageUrl} alt={title} className="w-80 h-48 object-cover ml-16" />
        </Link>
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full md:w-2/3 pr-4">
      <h4 className="text-2xl prompt-semibold mb-4 pb-2 border-b border-black">
        DEMOCRACIA EN AMÉRICA
      </h4>
      <NewsItem
        title="Lorem ipsum dolor sit amet, 
        consectetur 
        adipiscing elit"
        author="José Pérez"
        date="Hoy a las 11:30 a.m. UTC"
        imageUrl={news5}
      />
      <NewsItem
        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        author="José Pérez"
        date="Hoy a las 11:30 a.m. UTC"
        imageUrl={news5}
      />
      <NewsItem
        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        author="José Pérez"
        date="Hoy a las 11:30 a.m. UTC"
        imageUrl={news5}
      />
    </div>
  );
};

export default Democracia;
