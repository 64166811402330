import React from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Civish from "../imagenes/Civish.png";
const Precios = () => {
  return (
    <div className="bg-gray-100 min-h-screen font-sans">
      <header className="bg-civis p-6 d-flex justify-center">
      <Link to="/">
            <img src={Civish} className="ml-14 w-32 h-12" />
          </Link>
      </header>

      <main className="text-center py-8 sm:py-12">
        <h3 className="text-2xl sm:text-3xl prompt-medium mb-2">
          Actualidad en tu Bandeja de Entrada:
        </h3>
        <h3 className="text-2xl sm:text-3xl prompt-medium mb-8 sm:mb-14">
          Inscríbete y Mantente al Día
        </h3>

        <div className="flex flex-col sm:flex-row justify-center space-y-6 sm:space-y-0 sm:space-x-8">
          {/* Tarjeta de Precio Mensual */}
          <div className="bg-white p-6 sm:p-8 rounded-lg shadow-lg w-full max-w-sm">
            <h3 className="text-xl prompt-bold mb-2">Total acceso mensual</h3>
            <p className="text-2xl sm:text-3xl prompt-bold">
              $9,99 <span className="text-sm prompt-medium">per month</span>
            </p>
            <ul className="text-left mt-4 mb-6 space-y-2 prompt-regular text-gray-400">
              <li>• Acceso ilimitado en la web y aplicaciones</li>
              <li>
                • Actualizaciones de noticias en vivo las 24 horas, los 7 días
                de la semana
              </li>
              <li>• La cobertura política e internacional más completa</li>
              <li>• Historias interactivas innovadoras</li>
            </ul>
            <button className="bg-black prompt-regular text-white py-2 px-4 rounded-lg w-full">
              Suscríbete
            </button>
          </div>

          {/* Tarjeta de Precio Anual */}
          <div className="bg-white p-6 sm:p-8 rounded-lg shadow-lg w-full max-w-sm">
            <h3 className="text-xl prompt-bold mb-2">Total acceso anual</h3>
            <p className="text-2xl sm:text-3xl prompt-bold">
              $9,99 <span className="text-sm prompt-medium">per month</span>
            </p>
            <ul className="text-left mt-4 mb-6 space-y-2 prompt-regular text-gray-400">
              <li>• Acceso ilimitado en la web y aplicaciones</li>
              <li>
                • Actualizaciones de noticias en vivo las 24 horas, los 7 días
                de la semana
              </li>
              <li>• La cobertura política e internacional más completa</li>
              <li>• Historias interactivas innovadoras</li>
            </ul>
            <button className="bg-black text-white py-2 px-4 prompt-regular rounded-lg w-full">
              Suscríbete
            </button>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Precios;
