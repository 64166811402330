import React from "react";
import barquita from "../imagenes/LandingPage/barquita.jpg";
import campnou from "../imagenes/LandingPage/campnou.jpg";
import { Link } from "react-router-dom";

const OpinionesSection = () => {
  return (
    <section className="container mx-auto my-8  px-4">
      <div className="border-t border-black pb-4 mb-4">
        <h5 className=" prompt-semibold mt-4">
          OPINIONES <span className="text-2xl ml-4">&gt;</span>
        </h5>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* Main Opinion */}
        <div className="lg:col-span-2">
          <Link to="post" className="no-underline text-black">
            <img
              src={barquita}
              alt="Principal Opinión"
              className="w-full h-[400px] object-cover mb-4"
            />
            <h4 className="prompt-semibold mb-2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </h4>
          </Link>
          <Link to="perfil" className="no-underline">
            <h5 className="text-base prompt-light text-black mb-4">By José Pérez</h5>
          </Link>
          <h6 className="prompt-semibold border-t border-gray-300 pt-4 mt-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </h6>
        </div>

        {/* Additional Opinions */}
        <div className="grid grid-cols-2 gap-4 lg:gap-6">
          {[1, 2, 3, 4].map((item) => (
            <div key={item} className="">
              <Link to="post" className="no-underline text-black">
                <img
                  src={campnou}
                  alt={`Opinión ${item}`}
                  className="w-full h-36 object-cover mb-2"
                />
                <h6 className=" prompt-semibold leading-tight mb-1">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit
                </h6>
              </Link>
              <Link to="perfil" className="no-underline text-black">
                <p className="text-sm prompt-light">By José Pérez</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OpinionesSection;