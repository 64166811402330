import React from "react";
import Perfil1 from "../imagenes/Perfil/Perfil1.png";
export default function ProfileCard() {
  return (
    <div className="max-w-5xl mx-auto p-4 bg-white rounded-lg overflow-hidden mb-6 mt-10">
      <div className="flex flex-col md:flex-row gap-6">
        {/* Left column with image and social icons */}
        <div className="flex flex-col items-center gap-4">
          <div className="relative w-32 h-32">
            <img
              src={Perfil1}
              alt="Jenny Wilson"
              className="w-32 h-32 rounded-full mx-auto mb-4"
            />
          </div>
          <div className="flex gap-4">
            <a href="#" className="text-4xl">
              <i className="text-black fab fa-facebook-f"></i>
            </a>
            <a href="#" className="text-4xl">
              <i className="text-black fab fa-x-twitter"></i>
            </a>
            <a href="#" className="text-4xl">
              <i className="text-black fab fa-instagram"></i>
            </a>
          </div>
        </div>

        {/* Right column with content */}
        <div className="flex-1 bg-gray-300 rounded-lg p-6">
          <div className="space-y-4">
            <div>
              <h4 className="text-xl prompt-semibold mb-2">Jenny Wilson</h4>
              <h6 className="text-gray-600 text-sm prompt-regular mb-2">Politólogo</h6>
            </div>

            <div>
              <h6 className="text-base prompt-regular">
                Education: University of
                Chicago, BA in political science
              </h6>
            </div>

            <div>
              <h6 className="text-base prompt-regular">
                Jenny Wilson is a campaign reporter at The Washington Post,
                where she covers campaigns. She previously covered Congress and
                campaigns for Usa Today, National Journal Hotline and CNN. As a
                member of The Post's Next Generation initiative, she is part of
                the newsroom's efforts to engage younger and more diverse
                audiences both on- and off-platform.
              </h6>
            </div>

            <div className="border-b border-gray-700">
              <h6 className="text-base prompt-regular">
                Professional Affiliations:
                Asian American Journalists Association
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
