import React from "react";
import { Link } from "react-router-dom";


const Menu = () => {
  return (
    <div className="container mx-auto mt-8 px-4 prompt-extrabold">
      <nav className="flex flex-wrap justify-center space-x-4 md:space-x-24 border-b-2 border-black pb-4 ">
        <Link
          to='/categoria'
          className="text-base prompt-regular md:text-lg hover:text-gray-600 text-black no-underline"
        >
          <h5>Política</h5>  
        </Link>
        <Link
          to='/categoria'
          className="text-base prompt-regular md:text-lg  hover:text-gray-600 text-black no-underline"
        >
        <h5>Investigación</h5>  
        </Link>
        <Link
          to='/categoria'
          className="text-base prompt-regular md:text-lg  hover:text-gray-600 text-black no-underline"
        >
        <h5>Tecnología</h5>  
        </Link>
        <Link
          to='/categoria'
          className="text-base prompt-regular md:text-lg  hover:text-gray-600 text-black no-underline"
        >
        <h5>Mundo</h5>  
        </Link>
      </nav>
    </div>
  );
};


export default Menu;