import React from "react";
import { Link } from "react-router-dom";
import newsletter from "../imagenes/newsletter.png";
const FollowUs = () => {
  return (
    <div className="w-full md:w-1/3">
      <div className="border-t border-black pt-4 mb-2"></div>

      <h4 className="text-2xl prompt-semibold mb-4">SIGUENOS</h4>

      <div className="flex space-x-8 mb-6">
        <a href="#" className="text-4xl">
          <i className="text-black fab fa-facebook-f"></i>
        </a>
        <a href="#" className="text-4xl">
          <i className="text-black fab fa-x-twitter"></i>
        </a>
        <a href="#" className="text-4xl">
          <i className="text-black fab fa-instagram"></i>
        </a>
      </div>

      <div className="">
        <Link to="precios" className="no-underline">
            <img src={newsletter} alt="Newsletter" className="w-full h-32" />
        </Link>
        <Link to="post" className="no-underline text-black">
          <h6 className="text-base prompt-semibold mb-4">
            Mantente Informado: Recibe las Noticias Más Relevantes cada día
          </h6>
        </Link>
        <p className="text-sm prompt-regular">
          Obtenga lo mejor de Civis directamente en su bandeja de entrada con
          los boletines informativos adecuados para usted.
        </p>
      </div>
    </div>
  );
};

export default FollowUs;
