import React from 'react'

const Menu2 = () => {
  return (
    <header className="max-w-5xl mx-auto py-2 px-4 prompt-extrabold mt-2">
      <h1 className="text-4xl md:text-5xl font-bold text-center tracking-widest mb-10">
        <span className="inline-block align-middle">•</span>
        <span className="inline-block align-middle mx-2">POLITICA</span>
        <span className="inline-block align-middle">•</span>
      </h1>
      <nav>
        <ul className="flex justify-center space-x-4 md:space-x-16 ">
          <li className='text-lg'>
            <a href="#" className="text-black hover:text-gray-600 transition-colors no-underline prompt-regular">
             <h5 className='prompt-regular'>Elecciones</h5> 
            </a>
          </li>
          <li className='text-lg'>
            <a href="#" className="text-black hover:text-gray-600 transition-colors no-underline prompt-regular">
            <h5 className='prompt-regular'>Administración</h5> 
            </a>
          </li>
        </ul>
      </nav>
    
    </header>
  )
}

export default Menu2;