import React from 'react';
import f1 from './../imagenes/Facebook.png'
import google from './../imagenes/google.png'
import apple from './../imagenes/apple.png'
import { Link } from 'react-router-dom';

const Login = () => {
  return (
    <div className="bg-white min-h-screen flex items-center justify-center font-sans px-4 sm:px-0">
      <div className="w-full max-w-sm">
        <Link to='/' className='no-underline text-black'>
          <h1 className="text-4xl sm:text-6xl font-extrabold mb-16 text-center">civis</h1>
        </Link>
        <h2 className="text-lg sm:text-xl mb-2 text-center prompt-semibold">
          Inicia sesión o crea una cuenta
        </h2>
        <p className="text-xs sm:text-sm text-center prompt-regular mb-4">
          Ingresa tu e-mail para iniciar sesión
        </p>
        <input
          type="email"
          placeholder="email@domain.com"
          className="border border-gray-300 p-2 text-base prompt-regular rounded-lg w-full mb-3"
        />
        <Link to='AccountPage'>
        <button className="bg-black text-white py-2 px-4 rounded-lg w-full mb-4 prompt-regular text-sm">
          Sign up with email
        </button>
        </Link>
        <div className="text-center text-xs sm:text-sm text-gray-500 mb-4 prompt-regular">
          O continúe con
        </div>
        <button className="bg-gray-200 border border-gray-300 py-2 px-4 rounded-lg w-full mb-3 flex items-center justify-start">
          <img src={google} className="ml-2 w-4 sm:w-5" alt="Google" />
          <h3 className="prompt-bold ml-8 sm:ml-14 text-xs sm:text-sm">
            Continúe con Google
          </h3>
        </button>
        <button className="bg-gray-200 border border-gray-300 py-2 px-4 rounded-lg w-full mb-3 flex items-center justify-start">
          <img src={f1} className="w-6 sm:w-8" alt="Facebook" />
          <h3 className="prompt-bold ml-6 sm:ml-12 text-xs sm:text-sm">
            Continúe con Facebook
          </h3>
        </button>
        <button className="bg-gray-200 border-gray-300 py-2 px-4 rounded-lg w-full mb-4 flex items-center justify-start">
          <img src={apple} alt="Apple" className="ml-2 w-4 sm:w-5" />
          <p className="ml-8 sm:ml-14 text-xs sm:text-sm prompt-bold">
            Continúe con Apple
          </p>
        </button>
        <p className="text-center prompt-regular text-gray-500 text-xs">
          Al hacer clic en Continuar, acepta nuestros{' '}
          <a href="#" className="prompt-semibold text-gray-700">
            Términos de servicio
          </a>{' '}
          y{' '}
          <a href="#" className="prompt-semibold text-gray-700">
            Política de privacidad
          </a>
        </p>
      </div>
    </div>
  );
};

export default Login;

