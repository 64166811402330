import { Search, User } from "lucide-react"
import { Link } from 'react-router-dom';
import Footer from "./Footer";
import Civish from "../imagenes/Civish.png"
export default function AccountPage() {
  return (
    <div className="min-h-screen flex flex-col bg-white">
      {/* Header */}
      <header className="bg-civis px-4 py-6 flex items-center justify-center space-x-96"> 
        <button className="text-white">
          <Search className="w-5 h-5" />
        </button>
        <Link to="/" className=" ">
        
        <img src={Civish} className="ml-14 w-32 h-12"/>
        </Link>
        <Link to="login" className="text-white text-base prompt-thin no-underline ">
          Iniciar sesión
        </Link>
      </header>

      {/* Main Content */}
      <main className="flex-1  mx-auto max-w-lg px-2 py-8 space-y-6 ma">
        {/* User Profile */}
        <div className="text-center space-y-4">
          <div className="inline-block p-4">
            <User className="w-20 h-20" />
          </div>
          <div className="space-y-1">
            <h5 className="text-xl prompt-regular">Juan José Santana Hurtado</h5>
            <p className="text-sm text-gray-600">
              Has iniciado sesión como jjhurtado@gmail.com
            </p>
            <p className="text-sm">
              ¿No eres tú?{" "}
              <Link href="/logout" className="text-black underline">
                Cierra sesión aquí
              </Link>
            </p>
          </div>
        </div>

        {/* Credentials */}
        <div className="bg-fondogris rounded-lg p-6 space-y-6">
          <h4 className="text-xl prompt-semibold">Credenciales</h4>
          <div className="space-y-2">
            <h5 className="block text-sm prompt-semibold">Email</h5>
            <h5 className="text-sm prompt-light  ">jjhurtado@gmail.com</h5>
            <div className="border-b lineagris mt-4"></div>
          </div>
          <div className="space-y-2">
            <h5 className="block text-sm prompt-semibold">Contraseña</h5>
            <h5 className="text-sm underline prompt-light">Cambiar contraseña</h5>
          </div>
        </div>

        {/* Account Deletion */}
        <div className="bg-fondogris rounded-lg p-6 space-y-4">
          <h4 className="text-xl prompt-semibold">¿Quieres darte de baja?</h4>
          <h5 className="text-sm prompt-light ">
            Si quieres darte de baja pincha{" "}
            <Link href="/delete-account" className="underline">
              aquí
            </Link>{" "}
            y te enviaremos un correo para verificar que eres tú y completar el proceso.
          </h5>
        </div>

        <div className="bg-fondogris rounded-lg p-6">
          <h4 className="text-xl prompt-semibold">Eliminar cuenta</h4>
        </div>
      </main>

    <Footer/>
    </div>
  )
}

