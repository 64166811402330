import { Search, Send } from "lucide-react";
import Menu from "./Menu";
import bill from "../imagenes/bill.png";
import Footer from "./Footer";
import PostNews from "./PostNews";
import CommentSetcion from "./CommentSection";
import { Link } from "react-router-dom";
import Civish from "../imagenes/Civish.png";
export default function Post() {
  return (
    <div className="min-h-screen bg-white font-sans">
      {/* Header */}
      <header className="bg-civis px-4 py-6 flex items-center justify-center space-x-96"> 
        <button className="text-white">
          <Search className="w-5 h-5" />
        </button>
        <Link to="/" className=" ">
        
        <img src={Civish} className="ml-14 w-32 h-12"/>
        </Link>
        <Link to="login" className="text-white text-base prompt-regular no-underline ">
          Iniciar sesión
        </Link>
      </header>

      {/* Navigation */}
      <Menu />

      {/* Main Content */}
      <main className="container mx-auto mt-8 px-4 md:px-0">
        <div className="flex flex-col md:flex-row md:space-x-8">
          <div className="md:w-2/3">
            <h2 className="text-4xl prompt-regular mb-4 mt-20">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod
            </h2>
            <div className="text-sm  text-gray-600 mb-4">
              <span className="prompt-semibold">By José Pérez</span>
              <span className="mx-2">•</span>
              <div>
                <span className="prompt-regular">
                  Subido el 10 Junio, 2024 - Actualizado el 11 Junio, 2024 -
                  3:15 UTC
                </span>
              </div>
            </div>
            <div className="flex space-x-4 mb-6">
              <button className="flex items-center space-x-2 text-xl text-black">
                <span className="p-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-8 h-8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                    />
                  </svg>
                </span>
                <span>Save</span>
              </button>
              <button className="flex items-center space-x-2 text-xl text-black">
                <Send className="w-8 h-8" />
                <span>Share</span>
              </button>
            </div>
          </div>
          <div className="md:w-1/3">
            <img src={bill} alt="Man with glasses" className="w-full h-auto" />
            <p className="text-sm prompt-regular text-gray-600 mt-2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore
            </p>
          </div>
        </div>
      </main>
      <PostNews />
      <CommentSetcion />
      <Footer />
    </div>
  );
}
