import React from "react";
import reload from "../imagenes/reload.png";
import t2 from "../imagenes/Post/t2.png";
import blackarrow from "../imagenes/blackarrow.png";
const TechnologyItem = ({ title, imageSrc }) => (
  <div className="flex justify-between items-center mb-5 border-b border-gray-500">
    <p className="text-base prompt-regular pr-2">{title}</p>
    <img
      src={imageSrc}
      alt="Technology"
      className="w-20 h-16  object-cover rounded mb-3"
    />
  </div>
);

export default function TechnologyCard() {
  return (
    <div className="bg-gray-100 p-4 rounded-lg shadow-md max-w-sm">
      <button>
      <img src={blackarrow} alt="blackarrow" className="w-7 h-7" />
      </button>
      <div className=" d-flex justify-center mb-4 ">
        <h4 className="text-3xl prompt-semibold text-center">Tecnología</h4>
      </div>

      <TechnologyItem
        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        imageSrc={t2}
      />
      <TechnologyItem
        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        imageSrc={t2}
      />
      <TechnologyItem
        title="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
        imageSrc={t2}
      />

      <div className="flex justify-center">
        <div className="flex flex-wra">
          <button className="flex items-center prompt-regular">
            Mira un tema diferente
          </button>
          <button>
          <img src={reload} alt="Reload" className="w-6 h-6 ml-2" />

          </button>
        </div>
      </div>
    </div>
  );
}
