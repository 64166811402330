import { Power } from "lucide-react";
import user from '../imagenes/user.png'
export default function UserMenu() {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="w-64 bg-white p-6 flex flex-col items-center space-y-6 shadow-lg rounded-lg">
        {/* User Icon */}
        <div className="w-16 h-16 flex items-center justify-center">
          <img src={user} className="w-16 h-16 rounded-full" alt="user" />
        </div>

        {/* User Name */}
        <div className="text-center">
          <h5 className="text-lg prompt-regular">Juan José Santana</h5>
          <p className="text-lg prompt-regular">Hurtado</p>
        </div>

        {/* Menu Items */}
        <div className="w-full space-y-4 text-right">
          <button className="w-full text-right transition-colors">
            Mi cuenta
          </button>

          <button className="w-full prompt-light text-right flex items-center justify-end gap-2 transition-colors">
            Artículos guardados
          </button>

          <button className="w-full prompt-light text-right flex items-center justify-end gap-2 transition-colors">
          <Power className="w-4 h-4" />
            Cerrar sesión
          </button>
        </div>
      </div>
    </div>
  );
}
