import React from 'react';
import Login from './components/Login';
import Precios from './components/Precios';
import LandingPage from './components/LandingPage';
import Post from './components/Post';
import Perfil from './components/Perfil';
import Categoria from './components/Categoria';
import AccountPage from './components/AccountPage';
import UserMenu from './components/UserMenu';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import '../src/index.css'
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<LandingPage />} />
          <Route path="login" element={<Login />} />
          <Route path="precios" element={<Precios />} />
          <Route path="post" element={<Post />} />
          <Route path="perfil" element={<Perfil />} />
          <Route path="categoria" element={<Categoria />} />
          <Route path='usermenu' element={<UserMenu/>}></Route>
        </Route>
        <Route>
          <Route path='categoria/login' element={<Login/>}></Route>
          <Route path='categoria/precios' element={<Precios/>}></Route>
          <Route path='categoria/perfil' element={<Perfil/>}></Route>
          <Route path='categoria/perfil/login' element={<Login/>}></Route>
          <Route path='categoria/perfil/precios' element={<Precios/>}></Route>
          <Route path='categoria/post' element={<Post/>}></Route>
          <Route path='perfil/login' element={<Login/>}></Route>
          <Route path='perfil/precios' element={<Precios/>}></Route>
          <Route path='perfil/perfil' element={<Perfil/>}></Route>
          <Route path='post/perfil' element={<Perfil/>}></Route>
          <Route path='post/login' element={<Login/>}></Route>
          <Route path='post/precios' element={<Precios/>}></Route>
          <Route path='perfil/post' element={<Post/>}></Route>
          <Route path='post/post' element={<Post/>}></Route>
          <Route path='login/AccountPage' element={<AccountPage/>}></Route>
          <Route path='login/AccountPage/login' element={<Login/>}></Route>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
