import React from "react";
import { Search, Share } from "lucide-react";
import Footer from "./Footer";
import Menu2 from "./menu2";
import PoliticaSetcion from "./PoliticaSection";
import PoliticaNews from "./PoliticaNews";
import DemocraciaNews from "./DemocraciaNews";
import Civish from "../imagenes/Civish.png";
import { Link } from "react-router-dom";
export default function Categoria() {
  return (
    <div className="min-h-screen bg-white font-sans">
      {/* Header */}
      <header className="bg-civis px-4 py-6 flex items-center justify-center space-x-96"> 
        <button className="text-white">
          <Search className="w-5 h-5" />
        </button>
        <Link to="/" className=" ">
        
        <img src={Civish} className="ml-14 w-32 h-12"/>
        </Link>
        <Link to="login" className="text-white text-base prompt-regular no-underline ">
          Iniciar sesión
        </Link>
      </header>
      <Menu2 />
      <PoliticaSetcion />
      <PoliticaNews />
      <DemocraciaNews />
      <Footer />
    </div>
  );
}
